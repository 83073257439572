import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jws inspect`}</strong>{` -- return the decoded JWS without verification`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jws inspect
--insecure [--json]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jws inspect`}</strong>{` reads a JWS data structure from STDIN, decodes it,
and outputs the payload on STDERR. Since this command does not verify the JWS
you must pass `}<strong parentName="p">{`--insecure`}</strong>{` as a misuse prevention mechanism.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jws`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--json`}</strong>{`
Displays the header, payload and signature as a JSON object. The payload will
be encoded using Base64.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      